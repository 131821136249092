var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('el-row',{staticStyle:{"margin-top":"10px","margin-left":"10px"}},[_c('el-button',{staticStyle:{"margin-bottom":"10px"},attrs:{"type":"primary"},on:{"click":function($event){_vm.dialog.brandAddState = true}}},[_vm._v("添加品牌")])],1),_c('el-collapse',{attrs:{"accordion":""},model:{value:(_vm.activeNames),callback:function ($$v) {_vm.activeNames=$$v},expression:"activeNames"}},_vm._l((_vm.table.storeBrandList),function(item,index){return _c('el-collapse-item',{key:index,attrs:{"name":index}},[_c('template',{slot:"title"},[_c('img',{staticStyle:{"height":"100%","cursor":"zoom-in","border-radius":"10px","margin-left":"2%"},attrs:{"src":'https://brand-image-1304365928.cos.ap-shanghai.myqcloud.com/'+item.brandImage,"alt":"加载失败"},on:{"click":function($event){$event.stopPropagation();return _vm.openImage(item.brandImage)},"error":($event)=>{
                        $event.target.src='https://isasaerp-img-1304365928.cos.ap-shanghai.myqcloud.com/logoFont.jpg?imageMogr2/rquality/2';
                      }}}),_c('p',{staticStyle:{"margin-left":"2%"}},[_vm._v(_vm._s(item.brandName))]),_c('i',{staticClass:"header-icon el-icon-edit",staticStyle:{"margin-left":"2%","font-size":"20px"},on:{"click":function($event){$event.stopPropagation();return _vm.openEdit(item)}}})]),_c('el-row',{staticStyle:{"padding":"1%"},attrs:{"gutter":20}},[_c('el-col',{staticStyle:{"display":"flex","flex-direction":"column","align-items":"center"},attrs:{"span":4}},[_c('div',{staticStyle:{"cursor":"pointer","width":"14vw","height":"19vw","border":"1px dashed  gray","border-radius":"10px","display":"flex","justify-content":"center","align-items":"center"},on:{"click":function($event){return _vm.openSeries(item)}}},[_c('i',{staticClass:"el-icon-plus",staticStyle:{"font-size":"50px"}})]),_c('p',[_vm._v("添加系列")])]),_vm._l((item.storeSeriesVOS),function(itemChild,indexChild){return _c('el-col',{key:indexChild,attrs:{"span":4}},[_c('div',{staticStyle:{"width":"14vw","height":"19vw"}},[_c('img',{staticStyle:{"width":"100%","height":"100%","cursor":"zoom-in","border-radius":"10px"},attrs:{"src":'https://brand-image-1304365928.cos.ap-shanghai.myqcloud.com/'+itemChild.seriesImg,"alt":"加载失败"},on:{"click":function($event){return _vm.openImage(itemChild.seriesImg)},"error":($event)=>{
                        $event.target.src='https://isasaerp-img-1304365928.cos.ap-shanghai.myqcloud.com/logoFont.jpg?imageMogr2/rquality/2';
                      }}})]),_c('el-row',{staticStyle:{"color":"var(--my-text-color)"}},[_c('el-col',{attrs:{"span":12}},[_vm._v(" "+_vm._s(itemChild.seriesName)+" ")]),_c('el-col',{staticStyle:{"text-align":"right"},attrs:{"span":12}},[_vm._v(" "+_vm._s(itemChild.seriesNum)+" ")])],1),_c('el-row',{staticStyle:{"cursor":"pointer"}},[_c('el-col',{attrs:{"span":8}},[_c('p',{staticStyle:{"color":"var(--my-describe-color)"},on:{"click":()=>{
                     _vm.dialog.storeSeries=itemChild;
                     _vm.dialog.seriesEditState=true;
                    }}},[_vm._v("编辑")])])],1)],1)})],2)],2)}),1),(_vm.dialog.brandAddState)?_c('brandAdd',{attrs:{"state":_vm.dialog.brandAddState},on:{"close":_vm.close}}):_vm._e(),(_vm.dialog.brandEditState)?_c('brandEdit',{attrs:{"storeBrand":_vm.dialog.storeBrand,"state":_vm.dialog.brandEditState},on:{"close":_vm.close}}):_vm._e(),(_vm.dialog.seriesAddState)?_c('seriesAdd',{attrs:{"state":_vm.dialog.seriesAddState,"storeBrand":_vm.dialog.storeBrand},on:{"close":_vm.close}}):_vm._e(),(_vm.dialog.seriesEditState)?_c('seriesEdit',{attrs:{"state":_vm.dialog.seriesEditState,"series":_vm.dialog.storeSeries},on:{"close":_vm.close}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }