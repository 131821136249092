<template xmlns:el-col="http://www.w3.org/1999/html">
  <div>
    <el-row style="margin-top: 10px;margin-left: 10px">
      <el-button type="primary" @click="dialog.brandAddState = true" style="margin-bottom: 10px">添加品牌</el-button>
<!--      <el-table-->
<!--          :data="table.storeBrandList"-->
<!--          border-->
<!--          max-height="700"-->
<!--          :row-style="{height: '38px'}"-->
<!--          :cell-style="{padding: '0'}"-->
<!--          :header-cell-style="{background:'#fafafc',color:'#606266',padding: '9px 0'}"-->
<!--          :default-sort="{prop: 'id', order: 'descending'}"-->
<!--          style="width: 100%">-->
<!--        <el-table-column prop="brandImage" label="系列图" align="center">-->
<!--          <template slot-scope="scope">-->
<!--            <img :src="'https://brand-image-1304365928.cos.ap-shanghai.myqcloud.com/'+scope.row.brandImage"-->
<!--                 style="max-height: 100px"-->
<!--                 @click="openImage(scope.row.brandImage)"-->
<!--                 alt="系列图"-->
<!--                 @error="($event)=>{-->
<!--                        $event.target.src='https://isasaerp-img-1304365928.cos.ap-shanghai.myqcloud.com/logoFont.jpg?imageMogr2/rquality/2';-->
<!--                      }">-->
<!--          </template>-->
<!--        </el-table-column>-->
<!--        <el-table-column prop="brandName" label="品牌名称" align="center" />-->
<!--        <el-table-column prop="createDate" label="添加时间" align="center"/>-->
<!--        <el-table-column width="350" align="center" fixed="right" label="操作">-->
<!--          <template slot-scope="scope">-->
<!--            <el-button-group >-->
<!--              <el-button type="warning" size="mini" @click="openEdit(scope.row)">编辑</el-button>-->
<!--              <el-button type="primary" size="mini" @click="openSeriesList(scope.row)">查看系列</el-button>-->
<!--              <el-button type="success" size="mini" @click="openSeries(scope.row)">添加系列</el-button>-->
<!--            </el-button-group>-->
<!--          </template>-->
<!--        </el-table-column>-->
<!--      </el-table>-->
    </el-row>
    <el-collapse  v-model="activeNames" accordion>
      <el-collapse-item v-for="(item,index) in  table.storeBrandList" :key="index" :name="index">
        <template slot="title">
            <img
                style="height: 100%;cursor:zoom-in;border-radius: 10px;margin-left: 2%" @click.stop="openImage(item.brandImage)"
                :src="'https://brand-image-1304365928.cos.ap-shanghai.myqcloud.com/'+item.brandImage"
                alt="加载失败"
                @error="($event)=>{
                        $event.target.src='https://isasaerp-img-1304365928.cos.ap-shanghai.myqcloud.com/logoFont.jpg?imageMogr2/rquality/2';
                      }"/>
          <p style="margin-left: 2%;">{{item.brandName}}</p>
          <i class="header-icon el-icon-edit" style="margin-left: 2%;font-size: 20px" @click.stop="openEdit(item)"></i>
        </template>
        <el-row style="padding: 1%" :gutter="20">
          <el-col :span="4" style="display: flex;flex-direction: column;align-items: center;">
            <div @click="openSeries(item)"
                style="cursor: pointer;width: 14vw;height: 19vw;border:1px dashed  gray;border-radius: 10px;display: flex;justify-content: center;align-items: center">
              <i class="el-icon-plus" style="font-size: 50px"></i>
            </div>
            <p>添加系列</p>
          </el-col>
          <el-col v-for="(itemChild,indexChild) in item.storeSeriesVOS" :key="indexChild" :span="4">
            <div style="width: 14vw;height: 19vw;">
              <img
                  style="width: 100%; height: 100%;cursor:zoom-in;border-radius: 10px" @click="openImage(itemChild.seriesImg)"
                  :src="'https://brand-image-1304365928.cos.ap-shanghai.myqcloud.com/'+itemChild.seriesImg"
                  alt="加载失败"
                  @error="($event)=>{
                        $event.target.src='https://isasaerp-img-1304365928.cos.ap-shanghai.myqcloud.com/logoFont.jpg?imageMogr2/rquality/2';
                      }"/>
            </div>
            <el-row style="color: var(--my-text-color)">
              <el-col :span="12">
                {{itemChild.seriesName}}
              </el-col>
              <el-col :span="12" style="text-align: right">
                {{itemChild.seriesNum}}
              </el-col>
            </el-row>
            <el-row style="cursor: pointer">
              <el-col :span="8">
                <p style="color: var(--my-describe-color)"
                   @click="()=>{
                     dialog.storeSeries=itemChild;
                     dialog.seriesEditState=true;
                    }"
                >编辑</p>
              </el-col>
            </el-row>
          </el-col>
        </el-row>
      </el-collapse-item>
    </el-collapse>
    <brandAdd v-if="dialog.brandAddState" :state="dialog.brandAddState" @close="close"/>
    <brandEdit v-if="dialog.brandEditState" :storeBrand="dialog.storeBrand" :state="dialog.brandEditState" @close="close"/>
    <seriesAdd v-if="dialog.seriesAddState"  :state="dialog.seriesAddState" :storeBrand="dialog.storeBrand" @close="close"/>
    <seriesEdit v-if="dialog.seriesEditState"  :state="dialog.seriesEditState" :series="dialog.storeSeries" @close="close"/>
  </div>
</template>

<script>
import brandAdd from "@/pages/store/brand-list/brand-add";
import brandEdit from "@/pages/store/brand-list/brand-edit";
import seriesAdd from "@/pages/store/brand-list/store-series-add.vue";
import seriesEdit from "@/pages/store/brand-list/store-series-edit.vue";

export default {
  name: "brand-list",
  data() {
    return {
      table: {
        storeBrandList: [],
        page: 1,
        limit: 100,
        total: 0,
      },
      tenantCrop: localStorage.getItem("tenantCrop"),
      dialog: {
        brandAddState: false,
        brandEditState: false,
        seriesAddState: false,
        seriesEditState: false,
        storeBrand: {},
        storeSeries: {},
      },
      activeNames:[0],
    }
  },
  components: {
    brandAdd, brandEdit,seriesAdd ,seriesEdit
  },
  created() {
    this.queryStoreBrandList();
  },
  methods: {
    //查询客户列表
    queryStoreBrandList: function () {
      this.$axios({
        method: "GET",
        url: "/storeBrand/queryList",
        params: {
          tenantCrop:this.tenantCrop,
        }
      }).then(response => {

        this.table.storeBrandList = response.data.data;
        console.log(this.table.storeBrandList)
      })
    },
    openEdit(data){
      this.dialog.brandEditState = true
      this.dialog.storeBrand = data;
    },
    openSeries(data){
      this.dialog.seriesAddState = true
      this.dialog.storeBrand = data;
    },
    //放大图片
    openImage(link) {
      this.$viewerApi({
        images: ['https://brand-image-1304365928.cos.ap-shanghai.myqcloud.com/' + link],
      })
    },
    //更改每页长度
    handleSizeChange: function (val) {
      this.table.limit = val;
      this.queryStoreBrandList();
    },
    handleCurrentChange(val) {
      this.table.page = val
      this.queryStoreBrandList()
    },
    close: function (state) {
      this.dialog.brandAddState = state;
      this.dialog.brandEditState = state;
      this.dialog.seriesAddState = state;
      this.dialog.seriesEditState = state;
      this.queryStoreBrandList();
    },
  }
}
</script>

<style scoped>
.el-collapse-item >>> .el-collapse-item__header{
  padding: 1% 0;
  background-color: #f2f2f2;
  border: 1px solid #e0dfdf;
}
.el-collapse-item >>> .el-collapse-item__header{
  height: 80px;
}
</style>